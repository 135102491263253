@import url(https://fonts.googleapis.com/css?family=Work+Sans:300);
@import url(https://unpkg.com/firacode/distr/fira_code.css);
body,
input {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;
  color: #d8dee9;
}

h1 {
  font-weight: 300;
  font-size: 50px;
}

a,
button {
  color: #d8dee9;
}

button {
  font-size: 20px;
  background: #2e3440;
  padding: 20px;
  border-radius: 8px;
  border: none;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.Pane {
  overflow: hidden;
}

.Title_title__1v0E1 {
  display: inline-block;
  height: 80px;
  flex: 1 1;
  color: hsl(0, 0%, 95%);
  margin-top: 0;
  margin-bottom: 0;
  line-height: 80px;
  margin-left: 20px;
}

.Help_help__jW4Jy {
  display: flex;
  text-align: right;
}

.Help_help__jW4Jy a {
  margin: auto;
  color: hsl(0, 0%, 95%);
  text-decoration: none;
  font-weight: bold;
}

.Share_share__1Z6Gw {
  padding: 10px;
  margin-left: 40px;
}

.Share_share__1Z6Gw input {
  width: 400px;
  height: calc(100% - 20px);
  font-size: 20px;
  background: #2e3440;
  color: hsl(0, 0%, 95%);
  border: none;
  padding: 10px;
  border-radius: 8px;
}

.Share_label__3ovfH {
  margin-right: 10px;
}

.Header_header__1KUp7 {
  display: flex;
  flex-direction: row;
}

.Links_links__6ZX4w {
  display: flex;
  margin-left: 40px;
}

.Links_item__2X0Z8 {
  margin: auto;
  font-size: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.Links_item__2X0Z8:last-of-type {
  margin-right: 0;
}

.Editor_editor__304PK {
  height: 100%;
  width: 100%;
}

.Render_render__3JHeN {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;

  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.Render_loading__2KXt6 {
  -webkit-filter: grayscale(60%) blur(2px) brightness(104%);
          filter: grayscale(60%) blur(2px) brightness(104%);
}

.Preview_preview__2GW9E {
  flex: 1 1;

  overflow: auto;
  height: calc(100% - 40px);
  padding: 20px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: #fafafa;
  background-image: -webkit-linear-gradient(
      45deg,
      #eaeaea 25%,
      transparent 25%,
      transparent 75%,
      #eaeaea 75%,
      #eaeaea
    ),
    -webkit-linear-gradient(
      45deg,
      #eaeaea 25%,
      transparent 25%,
      transparent 75%,
      #eaeaea 75%,
      #eaeaea
    );
  background-image: linear-gradient(
      45deg,
      #eaeaea 25%,
      transparent 25%,
      transparent 75%,
      #eaeaea 75%,
      #eaeaea
    ),
    linear-gradient(
      45deg,
      #eaeaea 25%,
      transparent 25%,
      transparent 75%,
      #eaeaea 75%,
      #eaeaea
    );
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.Dialog_dialog__2k93t {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: calc(100% - 80px);

  background: #4c566a;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  text-align: center;

  padding: 30px;

  border-radius: 3px;

  z-index: 9999;
}

.Dialog_dialog__2k93t button {
  margin-top: 50px;
  width: 100%;
}

.App_app__1kX79 {
  background-color: #22272f;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App_body__ZTvxg {
  flex: 1 1;
  position: relative;
}


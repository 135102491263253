.dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 80px);

  background: #4c566a;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  text-align: center;

  padding: 30px;

  border-radius: 3px;

  z-index: 9999;
}

.dialog button {
  margin-top: 50px;
  width: 100%;
}

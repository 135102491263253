.render {
  transition: all 0.2s;

  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.loading {
  filter: grayscale(60%) blur(2px) brightness(104%);
}
